import React from 'react';
import { Link } from 'react-router-dom';

function Phone(props) {
    return (
        <div className='Phone'>
            <Link to="tel:+972504471445">
                050-447-1445
            </Link>
        </div>
    );
}

export default Phone;