import React from 'react';
import Data from './Data';
import Phone from './Phone';
import Carousel from './Carousel';
import Contact from './Contact';

import { ReactComponent as Wave } from '../svg/wave-haikei.svg';
import { ReactComponent as WaveDown } from '../svg/wave-haikeiDown.svg';
import Questions from './Questions';

function Index(props) {
    return (
        <div>

            <Data />

            <Phone />

            <div class="svg-container">
                <Wave />
            </div>

            {/* <h1 style={{ backgroundColor: "white", marginBottom: "0", fontWeight: "bold" }} >

                תמונות מפרוייקטים:

            </h1> */}

            <div id='Carousel' style={{ backgroundColor: "white" }}>
                <Carousel />
            </div>

            <div class="svg-container">
                <WaveDown />
            </div>

            <div class="questions-container">
                <Questions />
            </div>

            <br />
            <br />
            <br />
            <div class="contact-container">
                <div class="svg-container">
                    <Wave />
                </div>
                <h1>השאירו פרטים ונחזור אליכם:</h1>
                <Contact />
                <div class="svg-container">
                    <WaveDown />
                </div>
            </div>
        </div>
    );
}

export default Index;