import React from 'react';
import Phone from './Phone';
import Contact from './Contact';

function ContactPage(props) {

    return (
        <>
            <Phone />
            <Contact />
        </>
    );
}

export default ContactPage;