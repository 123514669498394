import React from 'react';
import Contact from './Contact';

function About(props) {

    const data1 = `חברתנו מתמחה בבניית בתים פרטיים ובנייני בוטיק ברחבי הארץ. החברה בבעלות
    אמיר כהן, קבלן רשום והנדסאי בניין בעל ידע עשיר וניסיון של מעל 20 שנה בניהול, פיקוח וביצוע
    של פרויקטים גדולים ומורכבים בבנייה בארץ ובעולם.`;
    const data2 = `הניסיון של החברה מתבטא בכל שלב בתהליך הבנייה. החל מבדיקת השטח, תכנון תקציבי מדויק,
    ביצוע איכותי בהתאם לתכניות הבניה ועד לעמידה קפדנית בלוחות הזמנים שתוכננו מראש ומסירת
    הפרויקט לשביעות רצון הלקוחות.`;
    const data3 = `יכולות מקצועיות גבוהות של כל אנשי הצוות בחברה מאפשרות בניה בסטנדרט גבוה, עבודת ביעילות
    ועל פי תקנים ישראליים מחמירים. רוב אנשי הצוות בחברה עובדים יחד כבר עשרות שנים, מאפיין זה
    נדיר בתחום ומעניק יכולת של עבודה בשיתוף פעולה ותיאום יוצא דופן.`;
    const data4 = `שירות אישי וקשוב שמעניקה החברה כולל זמינות מתמדת עבור כל המעורבים בפרויקט, תיאום ציפיות,
    גמישות ונכונות לבצע שינויים תוך כדי עבודה, וחשוב ביותר – ביצוע עבודה רציפה, תוך גילוי מקסימום
    מחויבות לפרויקט.`;
    const data5 = `מעטפת מקצועית רחבה הכוללת בעלי מקצוע מיומנים בתחומי בניה, אינסטלציה, חשמל, ריצוף, טיח,
    צבע, מיזוג אויר ועבודות גמר, הם חלק בלתי נפרד מצוות הביצוע של חברתנו. כל שלב
    בתהליך הביצוע מתועד ומאושר, על מנת להקנות פיקוח מלא בכל שלב בבנייה. בסיום עבודות הבניה
    מתמחה החברה במסירת הדירות, כולל ביצוע תיאומים, הכנת פרוטוקולים וביצוע תיקונים לפי הצורך.`;
    const data6 = `לקוחות מרוצים הם כרטיס הביקור של החברה ומהווים את מקור גאוותה. להשגת התוצאה הזו
    משקיעה החברה מאמצים רבים בשמירה על יחסי אנוש מעולים לאורך זמן ובחירת בעלי מקצוע
    מוסמכים, המעריכים עבודה איכותית.`;


    return (
        <>
            <div className='DataAbout' >
                <div className='aboutas'>
                    {/* <h1 style={{ fontSize: "40pt",margin:"20px 0" }}><u>עו"ד יצחק אדגו איצ'ו</u></h1> */}
                    <br />
                    <p style={{ fontSize: "18pt" }}>
                        {data1} <br /> <br />
                        {data2} <br /> <br />
                        {data3} <br /> <br />
                        {data4} <br /> <br />
                        {data5} <br /> <br />
                        {data6} <br /> <br />
                    </p>
                </div>
            </div>
            <Contact />
        </>

    );
}

export default About;