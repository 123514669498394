import React from 'react';
import Faq from 'react-faq-component';

const data = {
    title: "שאלות נפוצות",
    rows: [
        {
            title: "מה היתרון בבניית בית צמוד קרקע עד המפתח?",
            content: "בניית בית עד המפתח חוסכת לכם זמן יקר, כאבי ראש ודאגות. אנו מלווים אתכם לאורך כל התהליך, החל משלב התכנון ועד קבלת המפתח, ומבטיחים לכם שקט נפשי ובית מושלם שמתאים בדיוק לחלומות שלכם."
        },
        {
            title: "האם ניתן להוסיף ממ\"ד לבית קיים? וכמה זמן זה לוקח?",
            content: "בהחלט! אנו מתמחים בהוספת ממ\"דים למבנים קיימים, תוך הקפדה על עמידה בתקנים המחמירים ביותר. התהליך מהיר ויעיל, ונוכל לספק לכם לוחות זמנים מדויקים בהתאם לפרויקט הספציפי שלכם."
        },
        {
            title: "אילו סוגי תוספות בנייה ניתן לבצע?",
            content: "האפשרויות הן אינסופיות! החל מהוספת חדרים, הרחבת המטבח או הסלון, בניית יחידת דיור נפרדת ועד יצירת מרפסת חלומית - אנו נגשים לכם כל חלום."
        },
        {
            title: "איך מתבצע התכנון והרישוי מול הרשויות?",
            content: "אנו מנוסים בכל התהליכים הבירוקרטיים הכרוכים בתכנון ורישוי מול הרשויות. אנו נדאג לכל הניירת והאישורים הנדרשים, כך שתוכלו להתמקד במה שחשוב באמת - הבית החדש שלכם."
        },
        {
            title: "מה מבדיל אתכם מחברות בנייה אחרות?",
            content: "אנו מאמינים בשקיפות מלאה, מקצועיות ללא פשרות ושירות אישי לכל לקוח. הניסיון הרב שלנו, בשילוב עם התשוקה שלנו לבנייה איכותית, מבטיחים לכם תוצאה יוצאת דופן שתעלה על כל הציפיות שלכם."
        }
    ]
}

function Questions(props) {
    return (
        <div>
            <Faq data={data} 
                styles={{
                    bgColor: "##3B3C3C",
                    titleTextColor: "#3B3C3C",
                    rowTitleColor: "#3B3C3C",
                    rowContentColor: "#3B3C3C",
                    rowContentStyle: { textAlign: 'right !important' }, 
                    }} />
        </div>
    )
}



export default Questions;