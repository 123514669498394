import Menu from './components/Menu';
import Index from './components/Index';
import About from './components/About';
import ContactPage from './components/ContactPage';
import Footer from './components/Footer';

import './App.css';

import logo from './images/backGroundHeader.jpg'
import { Route, Routes } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Menu />
      
      <div id="logo">
        <img src={logo} alt="logo" width="100%" id="imgLogo" />
      </div>

      <Routes>
        <Route path='/' element={<Index />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<ContactPage />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
