import React from 'react';
import pic1 from '../images/pic1.jpg'
import pic2 from '../images/pic2.jpg'
import pic3 from '../images/pic3.jpg'
import pic4 from '../images/pic4.jpg'
import pic5 from '../images/pic5.jpg'
import pic6 from '../images/pic6.jpg'
import pic7 from '../images/pic7.jpg'
import pic8 from '../images/pic8.jpg'
import pic9 from '../images/pic9.jpg'
import pic10 from '../images/pic10.jpg'
import pic11 from '../images/pic11.jpg'

function Carousel(props) {
    return (
        <div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active" data-bs-interval="10000">
                    <img src={pic1} class="d-block w-100" alt="Home" />
                </div>
                <div class="carousel-item" data-bs-interval="2000">
                    <img src={pic2} class="d-block w-100" alt="Home" />
                </div>
                <div class="carousel-item">
                    <img src={pic3} class="d-block w-100" alt="Home" />
                </div>
                <div class="carousel-item">
                    <img src={pic4} class="d-block w-100" alt="Home" />
                </div>
                <div class="carousel-item">
                    <img src={pic5} class="d-block w-100" alt="Home" />
                </div>
                <div class="carousel-item">
                    <img src={pic6} class="d-block w-100" alt="Home" />
                </div>
                <div class="carousel-item">
                    <img src={pic7} class="d-block w-100" alt="Home" />
                </div>
                <div class="carousel-item">
                    <img src={pic8} class="d-block w-100" alt="Home" />
                </div>
                <div class="carousel-item">
                    <img src={pic9} class="d-block w-100" alt="Home" />
                </div>
                <div class="carousel-item">
                    <img src={pic10} class="d-block w-100" alt="Home" />
                </div>
                <div class="carousel-item">
                    <img src={pic11} class="d-block w-100" alt="Home" />
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
    );
}

export default Carousel;