import React from 'react';

function Data(props) {
    return (
        <div className='Data'>
            {/* <h1>בניית צמודי קרקע עד המפתח</h1>

            <h2>תוספות <span style={{ 'fontWeight': 'bolder' }}>ממ"דים</span> למבנים</h2>

            <h2>תוספות בנייה</h2>

            <h2>כולל תכנון ורישוי להיתר מול הרשויות</h2> */}
            {/* <h1> אמיר כהן חברת בנייה</h1> */}

            <h3 className='textData'>
                הבית המושלם שלכם מתחיל כאן.
                <br />
                עם ניסיון של שנים ומוניטין ללא רבב, אנו הופכים את חלומות הבנייה והשיפוץ שלכם למציאות.

                מתכנון מדוקדק ועד ביצוע ברמה הגבוהה ביותר,

                אנו מתחייבים למצוינות בכל שלב.

                צרו קשר עוד היום לפגישת ייעוץ אישית ותגלו כיצד אנו יכולים להפוך את החזון שלכם לבית אמיתי.
                השאירו פרטים ונצא יחד לדרך לבניית העתיד שלכם.
            </h3>
            <br />
            <br />

            <h1>מה אנחנו מציעים?</h1>
            <ul style={{ textAlign: "right" }}>
                <li>
                    <h2>בניית צמודי קרקע עד המפתח</h2>
                </li>
                <li>
                    <h2>תוספות <span style={{ 'fontWeight': 'bolder' }}>ממ"דים</span> למבנים</h2>
                </li>
                <li>
                    <h2>תוספות בנייה</h2>
                </li>
                <li>
                    <h2>כולל תכנון ורישוי להיתר מול הרשויות</h2>
                </li>
            </ul>
        </div>
    );
}

export default Data;