import React, { useRef, useState } from 'react';
import axios from 'axios';

function Contact(props) {

    const [flag, setFlag] = useState(false)

    const nameInputRef = useRef();
    const phoneInputRef = useRef();

    const handleSubmit = async (e) => {
        e.preventDefault();

        await axios.post("https://amircohennewsitebe.onrender.com/users", {
            name: nameInputRef.current.value,
            phone: phoneInputRef.current.value
        })

        setFlag(true)
    }

    return (
        <div className='Contact'>
            <form className='form' onSubmit={handleSubmit}>
                <div class="mb-3 mb-form">
                    <label for="exampleInputName1" class="form-label">שם פרטי:</label>
                    <input type="text" ref={nameInputRef} class="form-control" id="exampleInputName1" aria-describedby="nameHelp" />
                </div>
                <div class="mb-3 mb-form">
                    <label for="exampleInputPhone1" class="form-label">מספר טלפון:</label>
                    <input type="text" ref={phoneInputRef} class="form-control" id="exampleInputPhone1" />
                </div>
                <div className="mb-form">
                    <button type="submit" class="btn btn-dark">שלח</button>
                </div>
            </form>

            {flag && (
                <p style={{ fontSize: "28pt", width: "100%" }}>
                    תודה רבה, נחזור אליכם בהקדם!
                </p>
            )}
        </div>
    );
}

export default Contact;